<template>
    <div>
        <b-modal
            ref="edit-modal"
            id="edit-Modal"
            class="model_style modal-md manage-model-block"
            hide-header
            hide-footer
            centered
            size="lg"
            :visible="isVisible"
            @hide="hideModal"
        >
            <div class="manageModel">
                <div class="text-right cursor-pointer">
                    <img
                        class="pointer"
                        src="../../assets/close_icon.svg"
                        @click="closeEditModal"
                    />
                </div>
                <h5
                    class="my-2 title text-center"
                    v-if="editFileAttributes.length > 0"
                >
                    Edit attributes
                </h5>
                <div id="attrContent">
                    <div
                        v-for="(editAttr, indexEdit) in editFileAttributes"
                        :key="indexEdit"
                        class="document-upload"
                    >
                        <div
                            v-if="
                                editAttr.AttributeName == 'DocKeyword' ||
                                    editAttr.AttributeName == 'DocDescription'
                            "
                        >
                            <div
                                v-if="editAttr.AttributeName == 'DocKeyword'"
                                class="attrBox mb-2"
                            >
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Keywords (optional)',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <input
                                        type="text"
                                        autocomplete="off"
                                        v-model="editAttr.FreeTextVal"
                                        class="normal_input enterAddress"
                                    />
                                </CustomFloatingLabel>
                            </div>
                            <div
                                v-if="
                                    editAttr.AttributeName == 'DocDescription'
                                "
                                class="attrBox mb-2"
                            >
                                <textarea
                                    id="enterComments"
                                    v-model="editAttr.FreeTextVal"
                                    placeholder="Description (optional)"
                                ></textarea>
                                <small class="docSmall"
                                    >Keywords help with searching. Separate
                                    keywords with comma.</small
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(editAttr, indexEdit) in editFileAttributes"
                        :key="indexEdit"
                        class="document-upload"
                    >
                        <div
                            v-if="
                                editAttr.AttributeName != 'DocKeyword' &&
                                    editAttr.AttributeName != 'DocDescription'
                            "
                        >
                            <div
                                v-if="editAttr.IsFreeText"
                                class="attrBox mb-2"
                            >
                                <h6>
                                    {{ editAttr.AttributeName }}
                                </h6>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Attribute value',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    v-bind:editUserParams="1"
                                    class="mb-0"
                                >
                                    <input
                                        type="text"
                                        v-model="editAttr.FreeTextVal"
                                        autocomplete="off"
                                        class="normal_input"
                                    />
                                </CustomFloatingLabel>
                            </div>
                            <div v-else class="attrBox mb-2">
                                <h6>
                                    {{ editAttr.AttributeName }}
                                </h6>
                                <div
                                    v-if="editAttr.IsMultiSelect"
                                    class="multiBox"
                                >
                                    <div
                                        v-for="(permission,
                                        permIndex) in editAttr.AttributeValueTransact"
                                        :key="permIndex"
                                    >
                                        <b-form-checkbox
                                            v-model="
                                                permission.AttributeValueTextSelection
                                            "
                                        >
                                            {{ permission.AttributeValueText }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div v-else>
                                    <span
                                        v-for="(answers,
                                        pr) in editAttr.AttributeValueTransact"
                                        :key="pr"
                                    >
                                        <input
                                            class="singleSelect"
                                            type="radio"
                                            :value="answers.AttributeValueID"
                                            :checked="
                                                answers.AttributeValueID ==
                                                    editAttr.selectRadio
                                            "
                                            @change="
                                                getSelected(
                                                    answers.AttributeValueID,
                                                    indexEdit,
                                                    pr
                                                )
                                            "
                                        />
                                        <label class="ml-2" for="one">{{
                                            answers.AttributeValueText
                                        }}</label>
                                        <br />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-row class="float-right mt-4 col-6">
                    <b-button class="cancelButton" @click="closeEditModal">
                        Cancel
                    </b-button>
                    <b-button
                        class="applyButton"
                        @click="saveEditDirectoryAttribute"
                    >
                        Save attribute
                    </b-button>
                </b-row>
            </div>
        </b-modal>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :successToastMessage="successToastMessage"
            @hideToast="hideToast"
        ></ToastMessages>
    </div>
</template>

<script>
import { projectService } from '../../services/project.service';
import CustomFloatingLabel from '../CustomFloatingLabel';
import ToastMessages from '../ToastMessages.vue';
export default {
    props: {
        fileDetails: Object,
    },
    components: {
        CustomFloatingLabel,
        ToastMessages,
    },
    data() {
        return {
            isVisible: false,
            showSuccess: false,
            showFailure: false,
            successToastMessage: 'file attributes have been successfully saved',
            editFileAttributes: [],
            filenameDirectory: '',
            pathDirectoryEdit: '',
            DirectoryIdDirectoryEdit: '',
        };
    },
    methods: {
        getSelected(value1, indexParent, index) {
            this.editFileAttributes[indexParent].selectRadio = value1;
            for (let temp in this.editFileAttributes[indexParent]
                .AttributeValueTransact) {
                if (temp == index) {
                    this.editFileAttributes[indexParent].AttributeValueTransact[
                        temp
                    ].AttributeValueTextSelection = true;
                } else {
                    this.editFileAttributes[indexParent].AttributeValueTransact[
                        temp
                    ].AttributeValueTextSelection = false;
                }
            }
            this.$forceUpdate();
        },
        hideToast() {
            this.showSuccess = false;
            this.showFailure = false;
        },
        hideModal() {
            this.$emit('deleteFileDetails');
            this.isVisible = false;
        },
        saveEditDirectoryAttribute() {
            for (let attr in this.editFileAttributes) {
                this.editFileAttributes[attr].ProjectId = this.ProjectID;
            }
            this.editFileAttributes.forEach((a) => delete a.selectRadio);
            projectService
                .UpdateDocumentMetadataDetails(
                    this.filenameDirectory,
                    this.ProjectID,
                    this.editFileAttributes
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.showSuccess = true;
                        this.successToastMessage = res.data;
                        this.hideModal();
                    } else {
                        this.showFailure = true;
                    }
                });
        },
        closeEditModal() {
            this.isVisible = false;
        },
        getFileAttributes() {
            this.filenameDirectory = this.fileDetails.name;
            if (!this.fileDetails.fromFolderView) {
                this.pathDirectoryEdit =
                    this.fileDetails.projectId + this.fileDetails.filterPath;
            } else {
                this.pathDirectoryEdit = this.fileDetails.filterPath;
            }

            this.DirectoryIdDirectoryEdit = this.fileDetails.directoryID;
            projectService
                .GetDocumentEditMetadataDetails(
                    this.filenameDirectory,
                    this.pathDirectoryEdit,
                    this.DirectoryIdDirectoryEdit
                )
                .then((res) => {
                    this.editFileAttributes = res.data;
                    var emptyAttr = ['DocKeyword', 'DocDescription'];
                    if (this.editFileAttributes.length > 0) {
                        for (let attr in emptyAttr) {
                            if (
                                !this.editFileAttributes.some(
                                    (file) =>
                                        file.AttributeName === emptyAttr[attr]
                                )
                            ) {
                                this.editFileAttributes.push({
                                    AttributeName: emptyAttr[attr],
                                    AttributeValueTransact: [],
                                    DirectoryAttributeID: 0,
                                    DirectoryID: this.DirectoryIdDirectoryEdit,
                                    FreeTextVal: '',
                                    IsFreeText: true,
                                    IsMultiSelect: false,
                                });
                            }
                        }
                        for (let v in this.editFileAttributes) {
                            if (
                                !this.editFileAttributes[v].IsFreeText &&
                                !this.editFileAttributes[v].IsMultiSelect
                            ) {
                                let getIndex = this.editFileAttributes[
                                    v
                                ].AttributeValueTransact.findIndex(
                                    (item) =>
                                        item.AttributeValueTextSelection == true
                                );
                                if (getIndex > -1) {
                                    this.editFileAttributes[v][
                                        'selectRadio'
                                    ] = this.editFileAttributes[
                                        v
                                    ].AttributeValueTransact[
                                        getIndex
                                    ].AttributeValueID;
                                }
                            } else {
                                this.editFileAttributes[v][
                                    'selectRadio'
                                ] = null;
                            }
                        }
                    } else {
                        for (let attr in emptyAttr) {
                            this.editFileAttributes.push({
                                AttributeName: emptyAttr[attr],
                                AttributeValueTransact: [],
                                DirectoryAttributeID: 0,
                                DirectoryID: this.DirectoryIdDirectoryEdit,
                                FreeTextVal: '',
                                IsFreeText: true,
                                IsMultiSelect: false,
                            });
                        }
                    }
                    this.isVisible = true;
                });
        },
    },
    computed: {
        ProjectID() {
            return parseInt(
                this.$store.state.projects.selectedProjects[0].ProjectID
            );
        },
    },
    watch: {
        fileDetails(newVal, oldVal) {
            if (newVal !== null) {
                this.getFileAttributes();
            }
        },
    },
};
</script>

<style>
.cancelButton:hover {
    background-color: transparent;
    color: #006395;
}
.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
    float: left;
    padding: 14px 23.46px;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}
.applyButton {
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}
#enterComments {
    width: 475px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 10px 15px;
}
.input-content {
    width: 515px !important;
    background-color: white !important;
    border-radius: 4px !important;
    border: 1px solid #c6c6c6 !important;
    font-size: 16px !important;
    color: #1d1d1d !important;
    font-family: var(--ffr) !important;
    height: 48px;
    font-weight: normal !important;
    /* margin-top: 23px; */
}
.attrBox {
    border: 1px solid #ccc;
    padding: 10px;
    width: 499px;
    border-radius: 5px;
    background-color: #fbfbfb;
    margin: 0 auto;
}
.attrBox .input__container {
    width: 475px !important;
    background: white !important;
}
</style>

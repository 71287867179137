<template>
    <div>
        <b-modal
            ref="attribute-modal"
            class="model_style manage-model-block"
            hide-header
            hide-footer
            centered
            size="lg"
            :visible="isVisible"
            @hide="hideModal"
        >
            <div class="text-right cursor-pointer">
                <img
                    src="../../assets/close_icon.svg"
                    @click="hideReviewModal"
                />
            </div>
            <div v-if="isAddAttr">
                <div class="main-wrapper">
                    <div class="header-wrapper">
                        <span class="header-span">Manage attributes</span>
                    </div>
                    <div class="body-wrapper body-icon-self-align">
                        <CustomFloatingLabel
                            class="input__container input__container--content directoryAssign body-icon-self-align"
                            :config="{
                                label: 'Directory',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                type="text"
                                autocomplete="off"
                                v-model="this.directoryName"
                                class="normal_input"
                                :disabled="disabled"
                            />
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            class="body-icon-self-align"
                            :config="{
                                label: 'Attribute Name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                type="text"
                                autocomplete="off"
                                v-model="attribute.AttributeName"
                                class="normal_input"
                                @keypress="nameKeydown($event)"
                            />
                        </CustomFloatingLabel>
                        <div class="body-btn-wrapper body-icon-self-align">
                            <button
                                class="graphic"
                                v-bind:class="{
                                    'btn-not-selected': !isFreeTextClicked,
                                    'btn-selected': isFreeTextClicked,
                                }"
                                @click="activeFreeText"
                                placeholder="Free Text"
                                value="Free Text"
                                type="text"
                                readonly
                            >
                                Free Text
                            </button>

                            <button
                                class="graphic"
                                v-bind:class="{
                                    'btn-not-selected': isFreeTextClicked,
                                    'btn-selected': !isFreeTextClicked,
                                }"
                                @click="activeAssignedvalue"
                                type="button"
                                value="assigned"
                                readonly
                            >
                                Assigned Value
                            </button>
                        </div>
                        <div
                            v-if="!isFreeTextClicked"
                            class="add-value-wrapper body-icon-self-align"
                        >
                            <div
                                v-for="(assignedTextValue,
                                index) in attribute.AttributeValues"
                                :key="index"
                                class="pad-t-17 value-itm-wrapper"
                            >
                                <b-form-input
                                    type="text"
                                    autocomplete="off"
                                    @input="emptyAttrVal = false"
                                    v-model="
                                        assignedTextValue.AttributeValueText
                                    "
                                    class="input-content"
                                    :class="{
                                        'input-content-add-0': index === 0,
                                        'input-content-add': index !== 0,
                                    }"
                                ></b-form-input>
                                <a
                                    class="delete attribute ml-2"
                                    v-if="index != 0"
                                    @click="deleteAttribute(index)"
                                ></a>
                            </div>
                            <button
                                @click="addAttribute()"
                                type="button"
                                class="headergroup btn right btnEdit btn-secondary"
                            >
                                Add another value +
                            </button>
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="attribute.isMultiSelect"
                                name="checkbox-1"
                            >
                                Allow multiple values to be selected
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="footer-wrapper body-icon-self-align">
                        <b-button
                            class="mr-2 cancelButton"
                            @click="hideReviewModal"
                        >
                            Cancel
                        </b-button>

                        <b-button class="applyButton" @click="applyAttribute()">
                            Apply attributes
                        </b-button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="main-wrapper">
                    <div class="header-wrapper">
                        <span class="header-span">Manage attributes</span>
                    </div>
                    <div class="body-wrapper body-icon-self-align">
                        <CustomFloatingLabel
                            class="input__container input__container--content directoryAssign body-icon-self-align"
                            :config="{
                                label: 'Directory',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                type="text"
                                autocomplete="off"
                                v-model="this.directoryName"
                                class="normal_input"
                                :disabled="disabled"
                            />
                        </CustomFloatingLabel>
                    </div>
                    <div
                        class="body-wrapper body-icon-self-align edit-list-attr-wrapper"
                    >
                        <b-row
                            v-for="(preview, index) in managePreview"
                            :key="index"
                            class="first-row p-2"
                        >
                            <b-col class="p-0" lg="3" md="3">
                                <h5>
                                    {{ preview.AttributeName }}
                                </h5>
                            </b-col>
                            <b-col lg="6" md="6">
                                <ul>
                                    <li
                                        v-for="(val,
                                        indexVal) in preview.AttributeValues"
                                        :key="indexVal"
                                    >
                                        {{ val.AttributeValueText }}
                                    </li>
                                </ul>
                                <small v-if="preview.isMultiSelect"
                                    >Multiple values can be selected</small
                                >
                            </b-col>
                            <b-col class="p-0" lg="3" md="3">
                                <img
                                    @click="editAttr(index)"
                                    class="pointer"
                                    src="../../assets/edit.svg"
                                />
                                <!-- <span class="e-icons e-search"></span> -->
                                <img
                                    @click="deleteAttrPreview(index)"
                                    class="pointer"
                                    src="../../assets/delete_icon-dark.svg"
                                />
                            </b-col>
                        </b-row>
                        <button
                            type="text"
                            class="headergroup assign-header"
                            autocomplete="off"
                            @click="addNewAttribute"
                        >
                            Add another attribute +
                        </button>
                    </div>
                    <div class="footer-wrapper body-icon-self-align">
                        <b-button
                            class="mr-2 cancelButton"
                            @click="cancelSaveAttribute"
                        >
                            Cancel
                        </b-button>

                        <b-button class="applyButton" @click="saveAttribute()">
                            Save attributes
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :successToastMessage="successToastMessage"
            @hideToast="hideToast"
        ></ToastMessages>
    </div>
</template>

<script>
import CustomFloatingLabel from '../CustomFloatingLabel';
import { projectService } from '../../services/project.service';
import ToastMessages from '../ToastMessages.vue';

export default {
    components: {
        CustomFloatingLabel,
        ToastMessages,
    },
    props: {
        directoryId: Number,
        directoryName: String,
    },
    data() {
        return {
            isVisible: false,
            attributeTitle: '',
            appliedAttribute: false,
            attribute: {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            },
            disabled: true,
            isFreeTextClicked: true,
            isAddAttr: true,
            managePreview: [],
            isUpdate: false,
            successToastMessage: '',
            showSuccess: false,
            showFailure: false,
        };
    },
    methods: {
        hideToast() {
            this.showSuccess = false;
            this.showFailure = false;
        },
        editAttr(indx) {
            this.attribute = this.managePreview[indx];
            this.isFreeTextClicked = this.managePreview[indx].isFreeText;
            this.isAddAttr = true;
        },
        deleteAttrPreview(indx) {
            this.managePreview.splice(indx, 1);
        },
        applyAttribute() {
            if (
                this.attribute.AttributeName != '' &&
                this.attribute.AttributeName != null
            ) {
                let tempTempPbj = {
                    AttributeName: this.attribute.AttributeName,
                    AttributeValues: this.attribute.AttributeValues,
                    isMultiSelect: this.attribute.isMultiSelect,
                    isFreeText: this.isFreeTextClicked,
                    projectId: this.$store.state.projects.selectedProjects[0]
                        .ProjectID,
                    DirectoryID: this.directoryId,
                };
                let tempIndx = -1;

                this.managePreview.forEach((itm, idx) => {
                    if (itm.AttributeName === this.attribute.AttributeName) {
                        tempIndx = idx;
                        tempTempPbj['DirectoryAttributeID'] =
                            itm.DirectoryAttributeID;
                    }
                });
                if (tempIndx > -1) {
                    this.managePreview[tempIndx] = tempTempPbj;
                } else {
                    this.managePreview.push(tempTempPbj);
                }
            }
            this.isAddAttr = false;
        },
        cancelAction() {},
        cancelSaveAttribute() {
            this.isAddAttr = true;
        },
        addNewAttribute() {
            this.attribute = {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            };
            this.isFreeTextClicked = true;
            this.isAddAttr = true;
        },
        addAttribute() {
            this.attribute.AttributeValues.push({ AttributeValueText: '' });
        },
        deleteAttribute(index) {
            this.attribute.AttributeValues.splice(index, 1);
        },
        nameKeydown(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z0-9_]+$/.test(char)) return true;
            else e.preventDefault();
        },
        hideReviewModal() {
            this.isVisible = false;
        },
        hideModal() {
            this.attribute = {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            };
            this.isFreeTextClicked = true;
            this.$emit('deleteMangAttr');
            this.isVisible = false;
        },
        activeFreeText() {
            this.attribute.AttributeValues = [{ AttributeValueText: '' }];
            this.isFreeTextClicked = true;
        },
        activeAssignedvalue() {
            this.isFreeTextClicked = false;
        },
        saveAttribute() {
            for (let r = 0; r < this.managePreview.length; r++) {
                if (this.managePreview[r].isFreeText) {
                    this.managePreview[r].AttributeValues = [];
                }
            }
            if (!this.isUpdate) {
                projectService
                    .createDirectory(this.managePreview)
                    .then((res) => {
                        if (res.data.StatusCode === 201) {
                            this.successToastMessage =
                                'Attributes added successfully';
                            this.showSuccess = true;
                        }
                        if (res.data.StatusCode === 500) {
                            this.successToastMessage =
                                'Attributes already found';
                            this.showFailure = true;
                        }
                        this.isVisible = false;
                    })
                    .catch((err) => {
                        this.showFailure = true;
                        this.isVisible = false;
                    });
            } else {
                projectService
                    .updateDirectory(this.directoryId, this.managePreview)
                    .then((res) => {
                        this.successToastMessage =
                            'Attributes updated successfully';
                        this.showSuccess = true;
                        this.isVisible = false;
                    })
                    .catch((err) => {
                        this.showFailure = true;
                        this.isVisible = false;
                    });
            }
        },
        getDirectoryManageAttribute() {
            projectService
                .getDirectoryManageAttribute(this.directoryId)
                .then((res) => {
                    if (res.data.length) {
                        this.managePreview = [];
                        this.isAddAttr = false;
                        this.isUpdate = true;
                        for (let i = 0; i < res.data.length; i++) {
                            let attributeValues = [];
                            for (
                                let j = 0;
                                j < res.data[i].AttributeValue.length;
                                j++
                            ) {
                                attributeValues.push({
                                    AttributeValueText:
                                        res.data[i].AttributeValue[j]
                                            .AttributeValueText,
                                    AttributeValueID:
                                        res.data[i].AttributeValue[j]
                                            .AttributeValueID,
                                });
                            }
                            this.managePreview.push({
                                AttributeName: res.data[i].AttributeName,
                                AttributeValues: attributeValues,
                                isMultiSelect: res.data[i].IsMultiSelect,
                                isFreeText: res.data[i].IsFreeText,
                                DirectoryAttributeID:
                                    res.data[i].DirectoryAttributeID,
                                projectId: this.$store.state.projects
                                    .selectedProjects[0].ProjectID,
                                DirectoryID: res.data[i].DirectoryID,
                            });
                        }
                    } else {
                        this.isAddAttr = true;
                    }
                    this.isVisible = true;
                });
        },
    },
    watch: {
        directoryId(newValue, oldValue) {
            if (newValue !== 0) {
                (this.attribute = {
                    AttributeName: null,
                    AttributeValues: [{ AttributeValueText: '' }],
                    isMultiSelect: false,
                }),
                    this.getDirectoryManageAttribute();
            }
        },
    },
};
</script>

<style lang="scss">
.main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.header-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
        font: normal normal normal 25px/33px IBM Plex Sans;
        letter-spacing: 0;
        color: #161616;
    }
}
.body-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
}
.body-icon-self-align {
    align-self: center;
}
.slot-container {
    input {
        padding-left: 0 !important;
    }
}
.body-btn-wrapper {
    display: flex;
    flex-direction: row;
    width: 500px;

    button {
        width: 50%;
        padding: 0;
    }
}
.graphic {
    text-align: center;
    text-align: center;
    height: 48px;
    border: none !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    border: 1px solid #c6c6c6 !important;
    background: transparent;
}
.btn-not-selected {
    background-color: transparent !important;
    color: #000 !important;
}
.btn-selected {
    color: #fff !important;
    background-color: #000 !important;
}
.footer-wrapper {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}
.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
    cursor: pointer;
}
.add-value-wrapper {
    width: 500px;
    display: flex;
    flex-direction: column;
}
.input-content {
    background-color: white !important;
    border-radius: 4px !important;
    border: 1px solid #c6c6c6 !important;
    font-size: 16px !important;
    color: #1d1d1d !important;
    font-family: var(--ffr) !important;
    height: 48px;
    font-weight: normal !important;
}
.input-content-add-0 {
    width: 100% !important;
}
.input-content-add {
    width: 88% !important;
}
.delete {
    width: 10% !important;
    height: 48px;
    margin-top: 0px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
    margin-top: 20px;
    font: normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 1px solid #6e6e6e;
    color: #6e6e6e;
}
.headergroup.edit-header {
    width: 537px;
}
.value-itm-wrapper {
    display: flex;
    flex-direction: row;
}
.edit-list-attr-wrapper {
    width: 500px;
}
.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
</style>

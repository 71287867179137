<template>
    <b-modal
        ref="file-attribute-modal"
        class="model_style manage-model-block"
        id="file-attribute"
        hide-header
        hide-footer
        centered
        size="lg"
        :visible="isVisible"
        @hide="hideModal"
        ><div class="manageModel">
            <div class="text-right cursor-pointer">
                <img
                    class="pointer"
                    src="../../assets/close_icon.svg"
                    @click="closeFileModal"
                />
            </div>
            <h5 class="my-2 title text-center" v-if="files.length > 0">
                File attributes
            </h5>
            <span v-if="files.length > 0">
                <div class="file-attr-w100">
                    <ejs-grid
                        :selectionSettings="{ persistSelection: true }"
                        :filterSettings="filterOptions"
                        :dataSource="dataSource"
                        :columns="columns"
                        :commandClick="commandClick"
                    >
                    </ejs-grid>
                </div>
            </span>
            <span v-else>
                <h4 class="noAttribute">
                    No attributes available
                </h4>
            </span>
        </div>
    </b-modal>
</template>

<script>
import Vue from 'vue';
import { projectService } from '../../services/project.service';
import {
    FileManagerPlugin,
    NavigationPane,
    Toolbar,
    DetailsView,
} from '@syncfusion/ej2-vue-filemanager';
import {
    GridPlugin,
    Page,
    Sort,
    Filter,
    Search,
    Freeze,
    Grid,
    CommandColumn,
    Resize,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
export default {
    props: {
        fileFolderDetails: Object,
    },
    provide: {
        filemanager: [NavigationPane, DetailsView, Toolbar, CommandColumn],
        grid: [Page, Sort, Filter, Resize, Grid, CommandColumn],
    },
    data() {
        return {
            isVisible: false,
            files: [],
            displayFields: [],
            frozeColumns: 2,
            sortOptionsTbl: {
                columns: [],
            },
            filterOptions: { type: 'Excel' },
            options: { pageSizes: true },
            grid: '',
            dataSource: [],
            columns: [],
        };
    },
    methods: {
        hideModal() {
            this.isVisible = false;
            this.$emit('deleteFolderDetails');
        },
        commandClick: function(args) {
            let tempFile = this.files.filter(
                (file) => file.FileName === args.rowData.FileName
            );
            this.$emit('openFileAttrEdit', tempFile);
        },
        pageCenter: function(args) {},
        dataBound: function(args) {},
        actionComplete: function(args) {},
        onLoad: function() {},
        closeFileModal() {
            this.$refs['file-attribute-modal'].hide();
        },
        getFolderDetails() {
            projectService
                .GetDirectoryMetadataDetails(this.fileFolderDetails)
                .then((res) => {
                    this.files = res.data;
                    if (res.data.length > 0) {
                        let attrArray = Object.keys(res.data[0].Attributes);
                        let tempDataSource = [];
                        this.files.forEach((file) => {
                            let tmpAttrObj = {};
                            attrArray.forEach((attr) => {
                                tmpAttrObj[attr] = file.Attributes[attr];
                            });
                            tmpAttrObj['FileName'] = file['FileName'];
                            tempDataSource.push(tmpAttrObj);
                        });

                        this.dataSource = [...tempDataSource];
                        let fileCol = {
                            headerText: 'File name',
                            field: 'FileName',
                        };
                        let tempCols = [];
                        tempCols.push({
                            headerText: 'Edit',
                            field: 'Edit',
                            width: '70px',
                            commands: [
                                {
                                    buttonOption: {
                                        id: 'edit1',
                                        iconCss: ' e-icons e-edit',
                                        cssClass: 'e-flat',
                                    },
                                },
                            ],
                        }),
                            tempCols.push(fileCol);
                        attrArray.forEach((attr) => {
                            tempCols.push({
                                headerText: attr,
                                field: attr,
                            });
                        });

                        this.columns = [...tempCols];
                        this.isVisible = true;
                    } else {
                        this.isVisible = true;
                    }
                });
        },
    },
    watch: {
        fileFolderDetails(newVal, oldVal) {
            if (newVal !== null) {
                this.getFolderDetails();
            }
        },
    },
};
</script>

<style>
#Grid {
    width: 100% !important;
}
.noAttribute {
    text-align: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    color: #5e5e5e;
    font-size: 24px;
}
</style>

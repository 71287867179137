<template>
    <div class="fileManager">
        <toast-messages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
        <ejs-filemanager
            :id="'file-manager' + ProjectID"
            ref="file_instance"
            :beforeSend="beforeSend"
            :beforeImageLoad="beforeImageLoad"
            :beforeDownload="beforeDownload"
            :fileOpen="fileOpen"
            :contextMenuSettings="contextMenuSettings"
            :menuOpen="menuOpen"
            :ajaxSettings="ajaxSettings"
            :uploading="onUpload"
            :navigationPaneSettings="navigationPaneSettings"
            :toolbarSettings="toolSettings"
            :allowDragAndDrop="allowDragAndDrop"
            :success="onSuccess"
            :created="created"
            :uploadSettings="{ autoClose: true, maxFileSize: 1073741824 }"
            :menuClick="menuClick"
            :fileSelect="fileSelect"
            :height="fileManagerHeight"
            :view="view"
            :key="'file-manager' + ProjectID"
        >
            <!-- height="calc(100vh - 452px)" -->

            <!-- :cssClass="fileManagerClass" -->

            <!-- height="56vh" -->
        </ejs-filemanager>
        <EditFileAttributesModal
            :fileDetails="fileDetailsForAttr"
            @deleteFileDetails="deleteFileDetails"
        ></EditFileAttributesModal>
        <ViewFolderAttributesModal
            :fileFolderDetails="fileFolderDetails"
            @openFileAttrEdit="openFileAttrEdit"
            @deleteFolderDetails="deleteFolderDetails"
        ></ViewFolderAttributesModal>
        <ManageAttributes
            :directoryId="directoryIdForMngAttr"
            :directoryName="directoryName"
            @deleteMangAttr="deleteMangAttr"
        ></ManageAttributes>
        <!--TODO: Takeout view-modal into a seperate component-->
        <b-modal
            ref="view-modal"
            class="model_style manage-model-block"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="manageModel">
                <div class="text-right cursor-pointer">
                    <img
                        class="pointer"
                        src="../../assets/close_icon.svg"
                        @click="closeModal"
                    />
                </div>
                <h5
                    v-if="viewAttributes.length > 0"
                    class="mb-3 title text-center"
                >
                    View attributes
                </h5>
                <div class="view-form">
                    <b-container fluid>
                        <span v-if="viewAttributes.length > 0">
                            <span
                                v-for="(view, viewIndex) in viewAttributes"
                                :key="viewIndex"
                            >
                                <b-row class="my-2">
                                    <b-col sm="4">
                                        <label>{{ view.attribute }}</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <span
                                            v-for="(val,
                                            indexVal) in view.attrVal"
                                            :key="indexVal"
                                        >
                                            <p class="read-only">
                                                {{ val }}
                                            </p>
                                        </span>
                                    </b-col>
                                </b-row>
                                <hr />
                            </span>
                        </span>
                        <span v-else>
                            <h4 class="noAttribute">
                                No Attributes available
                            </h4>
                        </span>
                    </b-container>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ToastMessages from '../ToastMessages.vue';
import { commonService } from '../../services/common.service';
import { projectService } from '../../services/project.service';
import EditFileAttributesModal from '../../components/Modals/EditFileAttributesModal.vue';
import ViewFolderAttributesModal from '../../components/Modals/ViewFolderAttributesModal';
import ManageAttributes from '../../components/Modals/ManageAttributes';
import {
    FileManagerPlugin,
    DetailsView,
    NavigationPane,
    Toolbar,
} from '@syncfusion/ej2-vue-filemanager';

Vue.use(FileManagerPlugin);
export default {
    props: {
        fileManagerHeight: String,
    },
    data() {
        return {
            fileDetails: '',
            fileDetailsForAttr: null,
            directoryIdForMngAttr: 0,
            directoryName: '',
            directoryID: '',
            //fileManagerId: '',
            viewAttributes: [],
            contextMenuSettings: {
                file: [
                    'Open',
                    '|',
                    'Details',
                    '|',
                    'Download',
                    '|',
                    'View attributes',
                    '|',
                    'File attributes',
                    '|',
                    'Rename',
                    '|',
                    'NewFolder',
                    '|',
                    'Delete',
                ],
                folder: [
                    'Open',
                    '|',
                    'Details',
                    '|',
                    'File attributes',
                    '|',
                    'Manage attributes',
                    '|',
                    'Rename',
                    '|',
                    'NewFolder',
                    '|',
                    'Delete',
                    '|',
                    'Download',
                ],
                layout: ['SortBy', 'View', 'Refresh', '|', 'Details', '|'],
                visible: true,
            },
            navigationPaneSettings: { visible: true },
            toolSettings: {
                items: [
                    'Refresh',
                    'View',
                    'Details',
                    'SortBy',
                    'NewFolder',
                    'Delete',
                    'Rename',
                ],
                visible: true,
            },
            projectID: '',
            allowDragAndDrop: true,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            fileFolderDetails: null,
            currFileDetails: null,
            isVisible: 'true',
            previousPath: null,
            view: 'Details',
        };
    },
    components: {
        ToastMessages,
        EditFileAttributesModal,
        ViewFolderAttributesModal,
        ManageAttributes,
    },
    provide: {
        filemanager: [DetailsView, NavigationPane, Toolbar],
    },
    created() {
        //this.fileManagerId = 'file-manager' + this.ProjectID;
    },
    activated() {
        // called on initial mount
        // and every time it is re-inserted from the cache
        this.isVisible = true;
    },
    methods: {
        closeModal() {
            this.$refs['view-modal'].hide();
        },
        openFileAttrEdit(file) {
            this.fileDetails = {};
            this.fileDetails.name = file[0].FileName;
            this.fileDetails.projectId = file[0].ProjectId;
            this.fileDetails.filterPath = file[0].Path;
            this.fileDetails.directoryID = file[0].DirectoryId;
            this.fileDetails.fromFolderView = true;
            this.fileDetailsForAttr = this.fileDetails;
        },
        deleteFolderDetails() {
            this.fileFolderDetails = null;
        },
        deleteMangAttr() {
            this.directoryIdForMngAttr = 0;
        },
        deleteFileDetails() {
            this.fileDetailsForAttr = null;
        },
        menuOpen: function(args) {
            for (var i = 0; i < args.items.length; i++) {
                if (args.items[i].text === 'Manage attributes') {
                    args.items[i].iconCss = 'e-icons e-settings';
                }
                if (args.items[i].text === 'File attributes') {
                    args.items[i].iconCss = 'e-icons e-file';
                }
                if (args.items[i].text === 'View attributes') {
                    args.items[i].iconCss = 'e-icons e-display-for-review';
                }
            }
            if (args.target.offsetParent.classList.contains('e-treeview')) {
                var index = args.items.findIndex(
                    (p) => p.text == 'Manage attributes'
                );
                args.items.splice(index, 1);
                var index1 = args.items.findIndex(
                    (p) => p.text == 'File attributes'
                );
                args.items.splice(index1, 1);
                var index2 = args.items.findIndex((p) => p.text == 'Delete');
                args.items.splice(index2, 1);
            }
        },
        fileOpen: function(args) {
            this.directoryID = args.fileDetails.directoryID;
            if (args.module == 'LargeIconsView' && !args.fileDetails.isFile) {
                this.directoryID = args.fileDetails.directoryID;
            } else if (args.fileDetails.isFile) {
                this.$refs.file_instance.downloadFiles();
            }
        },
        fileSelect: function(args) {
            this.$emit('fileSelectInFM', args);
        },
        created() {
            if (this.path === '/') {
                //root folder
                this.allowDragAndDrop = false;
            } else {
                //not root folder
                this.allowDragAndDrop = true;
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
            }
        },
        onSuccess: function(args) {
            if (args.action == 'Upload') {
                let directory = this.path;
                //remove first and last character if it's a slash
                directory =
                    directory.slice(-1) == '/'
                        ? directory.slice(0, -1)
                        : directory;
                directory =
                    directory.slice(0, 1) == '/'
                        ? directory.substring(1)
                        : directory;
                projectService
                    .UpdateTblDocuments(
                        directory,
                        this.ProjectID,
                        args.result.file.name
                    )
                    .then((res) => {});
            }
        },
        displayFile() {
            setTimeout(function() {
                document
                    .getElementById('file-manager' + this.ProjectID)

                    .ej2_instances[0].refreshLayout();
            }, 1500);
        },
        onUpload: function(args) {
            let token = commonService.getCookie('accessToken');
            args.ajaxSettings.beforeSend = function(args) {
                //Setting authorization header
                args.httpRequest.setRequestHeader(
                    'Authorization',
                    'Bearer ' + token
                );
            };
        },
        beforeImageLoad: function(args) {
            let token = commonService.getCookie('accessToken');
            args.imageUrl = args.imageUrl + '&access_token=' + token;
        },
        beforeSend: function(args) {
            //set the upload path
            var data = JSON.parse(args.ajaxSettings.data);
            if (Array.isArray(data)) {
                this.path = data[0].path;
            } else {
                this.path = data.path;
            }

            if (args.action == 'read') {
                if (this.previousPath == null) {
                    this.previousPath = this.path;
                } else if (this.previousPath == this.path) {
                    this.previousPath = null;
                }
            } else {
                this.previousPath = null;
            }

            if (this.path === '/') {
                //do not allow upload button to appear at root folder
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                    ],
                    visible: true,
                };
                //do not allow drag and drop of existing files/folders at root
                this.allowDragAndDrop = false;
                //do not allow drag and drop upload of new files/folders at root
                if (args.action == 'Upload') {
                    args.cancel = true;
                    this.failureToastMessage =
                        'Please select a directory to upload';
                    this.showFailure = true;
                    this.showSuccess = false;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                }
                setTimeout(() => {
                    //if no path is specified, change syncfusion text to match
                    let emptyMainDiv = document.querySelector(
                        '.e-empty-content'
                    );
                    let emptySubDiv = document.querySelector(
                        '.e-empty-inner-content'
                    );
                }, 600);
            } else {
                //folder selected, access to upload
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
                this.allowDragAndDrop = true;
            }
            // this.$refs.file_instance.toolbarSettings = {
            //   items:toolSettings,
            //   visible: true
            // }
            let token = commonService.getCookie('accessToken');

            data['ProjectId'] = this.ProjectID;
            data['DirectoryID'] = this.DirectoryID;

            args.ajaxSettings.beforeSend = function(args) {
                //Setting authorization header
                args.httpRequest.setRequestHeader(
                    'Authorization',
                    'Bearer ' + token
                );
            };

            args.ajaxSettings.data = JSON.stringify(data);
        },
        beforeDownload: function(args) {
            var data = args.data.data[0];
            var path = data.filterPath + data.name + '/';
            let names = args.data.names;
            data.data = [
                {
                    projectId: data.projectId || this.ProjectID,
                    directoryID: data.directoryID,
                    path: path,
                    action: data.action,
                    newName: data.newName,
                    names: args.data.names,
                    targetPath: data.targetPath,
                    name: data.name,
                    size: data.size,
                    previousName: data.previousName,
                    targetData: data.targetData,
                    dateModified: data.dateModified,
                    dateCreated: data.dateCreated,
                    hasChild: data.hasChild,
                    isFile: data.isFile,
                    type: data.type,
                    id: data.id,
                    filterPath: data.filterPath,
                    filterId: data.filterId,
                    uploadFiles: data.uploadFiles,
                    caseSensitive: data.caseSensitive,
                    searchString: data.searchString,
                    showHiddenItems: data.showHiddenItems,
                    iconClass: data.iconClass,
                    nodeId: data.nodeId,
                    parentID: data.parentID,
                    selected: data.selected,
                    icon: data.icon,
                    data: [{}],
                    renameFiles: data.renameFiles,
                },
            ];
            args.data = data;
            args.cancel = true;
            var arr = [];
            arr.push(this.data);
            var xhr = new XMLHttpRequest();
            xhr.open(
                'POST',
                process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
                true
            );
            xhr.responseType = 'blob';
            xhr.onload = function() {
                if (this.status === 200) {
                    var name = '';
                    let header = xhr.getResponseHeader('Content-Disposition');
                    if (header && header.indexOf('attachment') !== -1) {
                        var regex = /name[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = regex.exec(header);
                        if (matches != null && matches[1])
                            name = matches[1].replace(/['"]/g, '');
                    }
                    var blob = new Blob([this.response], {
                        type: xhr.getResponseHeader('Content-Type'),
                    });
                    var anchorUrl = window.URL.createObjectURL(blob);
                    if (name) {
                        if (!name.toLowerCase().endsWith('.url')) {
                            var anchor = document.createElement('a');
                            anchor.href = anchorUrl;
                            anchor.download = name;
                            anchor.click();
                        }
                    } else {
                        window.location = anchorUrl;
                    }
                    setTimeout(async function() {
                        switch (args.data.type.toLowerCase()) {
                            case '.url':
                                fetch(anchorUrl)
                                    .then((response) => response.text())
                                    .then((data) => {
                                        data.split('\n').forEach((element) => {
                                            if (element.startsWith('URL=')) {
                                                window.open(
                                                    element.substring(4),
                                                    '_blank'
                                                );
                                            }
                                        });
                                    });
                                break;
                            case '.pdf':
                            case '.txt':
                                var type =
                                    args.data.type === '.pdf'
                                        ? 'application/pdf'
                                        : 'text/plain';
                                var blobFIle = await fetch(anchorUrl)
                                    .then((r) => r.blob())
                                    .then(
                                        (blobFile) =>
                                            new File(
                                                [blobFile],
                                                'fileNameGoesHere',
                                                {
                                                    type: type,
                                                }
                                            )
                                    );
                                var ad = URL.createObjectURL(blobFIle);
                                window.open(ad, '_blank');
                                break;
                            default:
                                URL.revokeObjectURL(anchorUrl);
                        }
                    }, 100);
                }
            };
            var fdata = new FormData();
            fdata.append('downloadInput', JSON.stringify(data));
            let token = commonService.getCookie('accessToken');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.send(fdata);
        },
        menuClick: async function(args) {
            if (args.fileDetails.length) {
                this.testName = args.fileDetails[0].name;
            }
            if (
                args.item.text === 'Manage attributes' ||
                args.item.text === 'Edit attributes'
            ) {
                this.directoryName = args.fileDetails[0].name;
                this.directoryIdForMngAttr = args.fileDetails[0].directoryID;
            } else if (args.item.text === 'View attributes') {
                this.fileDetails = args.fileDetails[0];
                var filename = this.fileDetails.name;
                await projectService
                    .GetDocumentMetadataDetails(this.directoryID, filename)
                    .then((res) => {
                        this.viewAttributes = [];
                        for (var key in res.data) {
                            var splitView = res.data[key].split('|');
                            const newArr = splitView.filter((a) => a);
                            this.viewAttributes.push({
                                attribute: key,
                                attrVal: newArr,
                            });
                        }
                    });
                this.$refs['view-modal'].show();
            } else if (args.item.text === 'File attributes') {
                if (args.fileDetails[0].isFile) {
                    this.fileDetails = args.fileDetails[0];
                    this.fileDetails.directoryID = this.directoryID;
                    this.fileDetailsForAttr = this.fileDetails;
                } else {
                    args.fileDetails[0].path =
                        args.fileDetails[0].filterPath +
                        args.fileDetails[0].name +
                        '/';
                    args.fileDetails[0].data = [
                        {
                            projectId: args.fileDetails[0].projectId,
                            directoryID: args.fileDetails[0].directoryID,
                            path: args.fileDetails[0].path,
                            action: args.fileDetails[0].action,
                            newName: args.fileDetails[0].newName,
                            names: args.fileDetails[0].names,
                            targetPath: args.fileDetails[0].targetPath,
                            name: args.fileDetails[0].name,
                            size: args.fileDetails[0].size,
                            previousName: args.fileDetails[0].previousName,
                            targetData: args.fileDetails[0].targetData,
                            dateModified: args.fileDetails[0].dateModified,
                            dateCreated: args.fileDetails[0].dateCreated,
                            hasChild: args.fileDetails[0].hasChild,
                            isFile: args.fileDetails[0].isFile,
                            type: args.fileDetails[0].type,
                            id: args.fileDetails[0].id,
                            filterPath: args.fileDetails[0].filterPath,
                            filterId: args.fileDetails[0].filterId,
                            uploadFiles: args.fileDetails[0].uploadFiles,
                            caseSensitive: args.fileDetails[0].caseSensitive,
                            searchString: args.fileDetails[0].searchString,
                            showHiddenItems:
                                args.fileDetails[0].showHiddenItems,
                            iconClass: args.fileDetails[0].iconClass,
                            nodeId: args.fileDetails[0].nodeId,
                            parentID: args.fileDetails[0].parentID,
                            selected: args.fileDetails[0].selected,
                            icon: args.fileDetails[0].icon,
                            data: [{}],
                            renameFiles: args.fileDetails[0].renameFiles,
                        },
                    ];
                    this.fileFolderDetails = args.fileDetails[0];
                }
            } else if (args.item.text === 'Open') {
                this.directoryID = args.fileDetails[0].directoryID;
            } else if (args.item.text === 'Rename') {
                this.DirectoryID = args.fileDetails[0].directoryID;
            }
        },
    },
    watch: {
        ProjectID(newVal, OldValue) {
            var filemanagerInstance = document.getElementById(
                'file-manager' + this.ProjectID
            );
            if (filemanagerInstance === null) {
                this.isVisible = false;
            } else {
                this.isVisible = true;
            }
        },
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        ajaxSettings() {
            let output = {
                url:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureFileOperations',
                getImageUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureGetImage?projectID=' +
                    this.ProjectID,
                uploadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureUpload?projectID=' +
                    this.ProjectID,
                downloadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
            };
            return output;
        },
    },
};
</script>
<style>
.e-filemanager .e-grid .e-checkbox-wrapper .e-icons.e-check {
    color: white;
}
.e-filemanager .e-grid .e-gridheader .e-checkbox-wrapper {
    margin: 0px;
    padding: 0px;
    position: relative;
    margin-right: -18px;
    float: right;
    margin-top: 7px;
}
</style>
<style scoped>
/* styles the upload response popup */
.e-popup.e-popup-open.e-dialog {
    width: 400px !important;
}
.e-upload .e-upload-files {
    width: 365px !important;
}
.e-upload .e-upload-files .e-upload-file-list {
    width: inherit !important;
}
.fileManager
    .e-upload
    .e-upload-files
    .e-upload-file-list
    .e-file-container
    .e-file-type {
    padding: 12px 0px 0px 0px !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-size {
    margin-top: 4px !important;
}
</style>
